import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { Group, TextInput, Button, Stack, PasswordInput, Modal } from '@mantine/core';
import {set_profile_data, set_loader} from '../../../redux/action/General/generalActions';
import Logo from '../../../assests/svg/Logo.svg';
import EyeIcon from '../../../assests/svg/EyeIcon.svg';
import GreenTick from '../../../assests/svg/GreenTick.svg';
import RedCross from '../../../assests/svg/RedCross.svg';
import { EmailVerificationInstructionsPost, VerifyEmailPost } from '../../../helpers/fetchApis/Auth';
import { showNotification } from '@mantine/notifications';


const TileDetailsModal= (props) => {
    
    return (
    <Modal
    opened={props.open}
    centered
    overlayOpacity={0.2}
    onClose={() => props.toggle()}
    title={props.tile.title}>
            <Stack style={{width: '100%', minHeight: '254px', maxHeight: '100vh'}}>
                <div style={{overflowY: 'scroll'}} dangerouslySetInnerHTML={{__html: props.tile.body}}/>
            </Stack>
        </Modal>
    )
}

export default TileDetailsModal;
