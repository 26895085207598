import { Grid, Group, SimpleGrid, Skeleton, Stack } from '@mantine/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { NotesGet, TenantGet } from '../../../helpers/fetchApis/Properties';
import DashboardCard from '../../components/CommonComponents/DashboardCard';
import DashboardDocumentsCard from '../../components/CommonComponents/DashboardDocumentsCard';
import DashboardMessagesCard from '../../components/CommonComponents/DashboardMessagesCard';
import DashboardPropertyCard from '../../components/CommonComponents/DashboardPropretyCard';
import DashboardTilesCard from '../../components/CommonComponents/DashboardTilesCard';
import FirstStepSectionCard from '../../components/CommonComponents/FirstStepSectionCard';
import FirstStepSectionCardCopy from '../../components/CommonComponents/FirstStepSectionCardCopy';

class Dashboard extends Component {

  state={
    allDashboardCardsAvailable: true,
    tiles: [
      // {
      //     html: "<p>Lorem ipsum dolor sit amet. Hic suscipit saepe et voluptas harum in aliquid exercitationem. Et molestiae galisum ut ipsam animi est dolorem odio et natus iure ut soluta doloremque non voluptatem natus.</p><p><img src=\"https://picsum.photos/200\"/></p><p>Sed tenetur veritatis eos molestiae optio sed voluptas voluptate nam nesciunt fugit non inventore internos. Et quia neque qui soluta dolore et vitae nesciunt vel nihil suscipit quo quod quis sed officiis dicta.</p>",
      //     id: 0,
      //     title: "Test title 1",
      //     propertyId: '0000',
      //     orderNumber: 1,
      //     isHidden: false,

      // },
      // {
      //     html: "<p><img src=\"https://picsum.photos/200\"/></p><p>Lorem ipsum dolor sit amet. Hic suscipit saepe et voluptas harum in aliquid exercitationem. Et molestiae galisum ut ipsam animi est dolorem odio et natus iure ut soluta doloremque non voluptatem natus.</p><p>Sed tenetur veritatis eos molestiae optio sed voluptas voluptate nam nesciunt fugit non inventore internos. Et quia neque qui soluta dolore et vitae nesciunt vel nihil suscipit quo quod quis sed officiis dicta.</p>",
      //     id: 1,
      //     title: "Test title 2",
      //     propertyId: '0000',
      //     orderNumber: 2,
      //     isHidden: false,

      // },
      // {
      //     html: "<p>Lorem ipsum dolor sit amet. Hic suscipit saepe et voluptas harum in aliquid exercitationem. Et molestiae galisum ut ipsam animi est dolorem odio et natus iure ut soluta doloremque non voluptatem natus.</p><p>Sed tenetur veritatis eos molestiae optio sed voluptas voluptate nam nesciunt fugit non inventore internos. Et quia neque qui soluta dolore et vitae nesciunt vel nihil suscipit quo quod quis sed officiis dicta.</p>",
      //     id: 2,
      //     title: "Test title 3",
      //     propertyId: '0000',
      //     orderNumber: 3,
      //     isHidden: false,
      // }
    ]
  }
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {profile, tenant, rentalUnit, property} = this.props;
    window.history.pushState({}, undefined, "/dashboard");
    this.setState({
      allDashboardCardsAvailable: (Object.keys(property).length > 0 && !property?.isConsumptionSectionHidden && !property?.isDocumentSectionHidden && !property?.isMessageSectionHidden)
    });
    this.getNotesByPropertyId();
  }

  componentDidUpdate(prevProps){
    if(prevProps.property !== this.props.property){
      this.setState({
        allDashboardCardsAvailable: (Object.keys(this.props.property).length > 0 && !this.props.property?.isConsumptionSectionHidden && !this.props.property?.isDocumentSectionHidden && !this.props.property?.isMessageSectionHidden)
      });
      
      this.getNotesByPropertyId();
    }
  }

  getNotesByPropertyId=()=>{
    const {property} = this.props;
    console.log('property: ', property);
    NotesGet(property.id, res=> {
        this.setState({tiles: res});
    });
  }

  render() {
    const {allDashboardCardsAvailable, tiles} = this.state;
    const {skeletonLoader, isMobile, isTablet, tenant, rentalUnit, property, rent} = this.props;
    console.log('componentDidMount: ', tenant, rentalUnit, property);
    return (
      <>
        <Stack className="container" id="respond-dashboard-page">
          {skeletonLoader ? 
          <Skeleton style={{height: '236px'}} />
          :
          <Group grow>
            <img style={{borderRadius: '4px', height: '237px', objectFit: 'cover'}} src={property && property.pictureLocation ? property.pictureLocation : './houseDefault.png'}/>
          </Group>}
          <Stack spacing={10}>
            {property && property.propertyAddress && property.propertyAddress.streetNo && <span className='dashboard-property-name' style={{width: 'fit-content'}}><Skeleton visible={skeletonLoader}>{property && property.propertyAddress.streetNo ? property.propertyAddress.streetNo : property.name}</Skeleton></span>}
            {property && property.propertyAddress && (property.propertyAddress.zipCode || property.propertyAddress.city) && <span className='dashboard-property-address' style={{width: 'fit-content'}}><Skeleton visible={skeletonLoader}>{`${property && property.propertyAddress.zipCode} ${property && property.propertyAddress.city}`}</Skeleton></span>}
          </Stack>
          {rent && rent.length > 0 && <div>
            <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
              <DashboardPropertyCard isMobile={isMobile} isTablet={isTablet} tenant={tenant} rentalUnit={rentalUnit} property={property} rent={rent} />
            </Skeleton>
          </div>}
          
          {/* <FirstStepSectionCard/> */}
          <Group //grow={!isMobile}
          >
            {/* <div style={{width: isMobile && '100%'}}>
              <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
                <Group grow>
                  <DashboardCard
                  heading={'Dokumente'}/>
                </Group>
              </Skeleton>
            </div> */}
            <div style={{width: '100%'}}>
              <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
                <Group grow={allDashboardCardsAvailable}
                noWrap={!allDashboardCardsAvailable}
                >
                  {Object.keys(property).length > 0 && !property?.isConsumptionSectionHidden &&
                  <DashboardCard
                  heading={'Verbräuche'}
                  tenant={tenant}
                  allDashboardCardsAvailable={allDashboardCardsAvailable}
                  />}
                  {!property?.isDocumentSectionHidden && 
                  <DashboardDocumentsCard
                  heading={'Dokumente'}
                  allDashboardCardsAvailable={allDashboardCardsAvailable}
                  />}
                  {!property?.isMessageSectionHidden && 
                  <DashboardMessagesCard
                  heading={'Nachrichten'}
                  allDashboardCardsAvailable={allDashboardCardsAvailable}/>}
                  {tiles.length > 0 &&
                  tiles.sort((a, b)=> a.displayOrder - b.displayOrder)
                  .map((tile)=>
                  <DashboardTilesCard
                  heading={tile.title}
                  tile={tile}
                  />)}
                </Group>
              </Skeleton>
            </div>
            {/* <div style={{ overflow: 'hidden', minWidth: '100%' }}>
              <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
                <SimpleGrid cols={3} grow>
                  {tiles.map((tile)=>
                  <DashboardTilesCard
                  heading={tile.title}
                  tile={tile}
                  />)}
                </SimpleGrid>
              </Skeleton>
            </div> */}
          </Group>
        </Stack>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      profile: state.generalReducer.profileData,
      isMobile: state.generalReducer.isMobile,
      isTablet: state.generalReducer.isTablet,
      account: state.generalReducer.accountData,
      skeletonLoader: state.generalReducer.skeletonLoader,
      tenant: state.generalReducer.tenantData,
      rentalUnit: state.generalReducer.rentalUnitData,
      property: state.generalReducer.propertyData,
      rent: state.generalReducer.rentData
  };
};

export default connect(mapStateToProps)(Dashboard);
